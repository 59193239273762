import * as React from 'react'

import { Navbar, NotFound, Footer } from '../views'

export default function NotFoundPage(){
  return (
    <main>
      <title>Page not found</title>
      <Navbar />
        <div className="main centered">
          <div className="container">
            <div className="section">
              <NotFound />
            </div>
          </div>
        </div>
      <Footer />
    </main>
  )
}
